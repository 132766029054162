import { tenSeconds } from "consts";
import {
  BaseProviderType,
  PermissionProviderType,
  SoftPhoneManagerType,
} from "models";
import { useProfile } from "providers/profile";
import { useToast } from "providers/toast";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { windowOpenSessionCleaner } from "utils";
import { SoftphoneIframe } from "./styles";

type SoftphoneContextType = {
  inReload: boolean;
  hasEmbedActions: boolean;
  onReloadWebPhone: () => void;
  getSoftphoneManager: () => SoftPhoneManagerType | undefined;
};

const popupSize = { x: 600, y: 400 };
const idIframe = "softphone-iframe";

let webPhoneWindow: Window | null;

const SoftphoneContext = createContext({} as SoftphoneContextType);

const Provider = ({ children }: BaseProviderType) => {
  const [inReload, setInReload] = useState(false);
  const { user, pIdProject, isSagoCore } = useProfile();
  const { t } = useTranslation();
  const { warning } = useToast();

  const hasAccess = useMemo(
    () => user?.hasAccessAttend && user?.hasDialerAPI && user?.deUrlWebPhone,
    [user]
  );

  const isNotAdmin = useMemo(
    () => pIdProject && !isSagoCore,
    [pIdProject, isSagoCore]
  );

  const hasPermission = useMemo(
    () => hasAccess && isNotAdmin,
    [hasAccess, isNotAdmin]
  );

  const useIframe = useMemo(() => user?.mustUseIFrameWebPhone ?? false, [user]);

  const hasEmbedActions = useMemo(
    () => !!user?.deUrlWebPhone && useIframe,
    [user, useIframe]
  );

  const iframe = useMemo(() => {
    if (hasPermission && useIframe)
      return (
        <SoftphoneIframe
          id={idIframe}
          title="Softphone"
          src={user!.deUrlWebPhone}
          allow="camera;microphone"
        />
      );
  }, [hasPermission, useIframe, user]);

  useEffect(() => {
    if (hasPermission && useIframe) {
      if (navigator.mediaDevices)
        navigator.mediaDevices
          ?.getUserMedia({ audio: true })
          .catch(() =>
            warning({ description: t("alerts.micPermission"), fixed: true })
          );
      else console.log("Browser does not support mediaDevices.");
    }
  }, [hasPermission, useIframe, warning, t]);

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (inReload) interval = setInterval(() => setInReload(false), tenSeconds);
    return () => clearInterval(interval);
  }, [inReload]);

  const onOpenWebPhone = useCallback(
    (reopen?: boolean) => {
      if (webPhoneWindow?.closed === false && !reopen) webPhoneWindow.focus();
      else {
        if (reopen) webPhoneWindow?.close();

        const { x, y } = popupSize;
        const left = window.screen.width / 2 - x / 2;
        const top = window.screen.height / 2 - y / 2;

        webPhoneWindow = windowOpenSessionCleaner(
          user?.deUrlWebPhone,
          "WebPhone",
          `width=${x},height=${y},left=${left},top=${top}`
        );

        webPhoneWindow?.location.reload();
      }
    },
    [user]
  );

  useEffect(() => {
    const onUnload = () => webPhoneWindow?.close();

    if (hasPermission && !useIframe) onOpenWebPhone();

    window.addEventListener("unload", onUnload);

    return () => {
      webPhoneWindow?.close();
      window.removeEventListener("unload", onUnload);
    };
  }, [hasPermission, useIframe, onOpenWebPhone]);

  const onReloadWebPhone = useCallback(() => {
    onOpenWebPhone(true);
    setInReload(true);
  }, [onOpenWebPhone]);

  const getSoftphoneManager = useCallback(() => {
    const iframe = document.getElementById(idIframe) as any;
    return iframe?.contentWindow?.SoftPhoneManager as
      | SoftPhoneManagerType
      | undefined;
  }, []);

  return (
    <SoftphoneContext.Provider
      value={{
        inReload,
        hasEmbedActions,
        onReloadWebPhone,
        getSoftphoneManager,
      }}
    >
      {children}
      {iframe}
    </SoftphoneContext.Provider>
  );
};

export const SoftphoneProvider = ({
  hasPermission = true,
  ...args
}: PermissionProviderType) => {
  if (!hasPermission) return <>{args.children}</>;

  return <Provider {...args} />;
};

export const useSoftphone = () => useContext(SoftphoneContext);
