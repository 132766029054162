import { AxiosRequestConfig } from "axios";
import { formDataConfig } from "consts";
import {
  AddAttendanceByDSRequest,
  AddAttendanceByDSResponse,
  AddAttendanceByOmniRequest,
  AddAttendanceByOmniResponse,
  AddAttendanceRequest,
  AddAttendanceResponse,
  AllowAttendanceResponse,
  AnswerQuestionRequest,
  AnswerQuestionResponse,
  CancelTransferAssitedRequest,
  CancelTransferAssitedResponse,
  ConfirmConferenceAttendanceRequest,
  ConfirmConferenceAttendanceResponse,
  ConfirmTransferAssitedRequest,
  ConfirmTransferAssitedResponse,
  CreateContactRequest,
  CreateContactResponse,
  CreateRoomResponse,
  DialAttendanceFromOtherMediaRequest,
  DialAttendanceFromOtherMediaResponse,
  DialAttendanceRequest,
  DialAttendanceResponse,
  DialConferenceAttendanceRequest,
  DialConferenceAttendanceResponse,
  DisableAutoFinishReponse,
  DisableAutoFinishRequest,
  FinishAttendanceRequest,
  FinishAttendanceResponse,
  FinishConferenceAttendanceRequest,
  FinishConferenceAttendanceResponse,
  GetAgentAPIsReponse,
  GetAgentAPIsRequest,
  GetAttendancesReponse,
  GetAttendancesRequest,
  GetFormFieldsRequest,
  GetFormFieldsResponse,
  GetFormOrderRequest,
  GetFormOrderResponse,
  GetFormTicketRequest,
  GetFormTicketResponse,
  GetHistoriesRequest,
  GetHistoriesResponse,
  GetIntegrationsRequest,
  GetIntegrationsResponse,
  GetMeQueuesRequest,
  GetMeQueuesResponse,
  GetMessagesIdRequest,
  GetMessagesIdResponse,
  GetMessagesRequest,
  GetMessagesResponse,
  GetOrderListRequest,
  GetOrderListResponse,
  GetQuestionRequest,
  GetQuestionResponse,
  GetQuizRequest,
  GetQuizResponse,
  GetTicketListRequest,
  GetTicketListResponse,
  GetTokenByRoomRequest,
  GetTokenByRoomResponse,
  HangupAttendanceRequest,
  HangupAttendanceResponse,
  ManageOrderItemRequest,
  ManageOrderItemResponse,
  ManageTicketItemRequest,
  ManageTicketItemResponse,
  PreviousQuestionRequest,
  PreviousQuestionResponse,
  RedialAttendanceRequest,
  RedialAttendanceResponse,
  ScheduleAttendanceRequest,
  ScheduleAttendanceResponse,
  SearchAttendanceRequest,
  SearchAttendanceResponse,
  SearchContactInDSRequest,
  SearchContactInDSResponse,
  SearchContactRequest,
  SearchContactResponse,
  SendFileRequest,
  SendFileResponse,
  SendFilesByTicketRequest,
  SendFilesByTicketResponse,
  SendHSMFromOtherMediaRequest,
  SendHSMFromOtherMediaResponse,
  SendHSMRequest,
  SendHSMResponse,
  SendMessageRequest,
  SendMessageResponse,
  ServicePathEnum,
  StartNewRecordRequest,
  StartNewRecordResponse,
  StartNPSSearchReponse,
  StartNPSSearchRequest,
  TransferAssistedAttendanceRequest,
  TransferAssistedAttendanceResponse,
  TransferAttendanceToQueueRequest,
  TransferAttendanceToQueueResponse,
  TransferAttendanceToUserRequest,
  TransferAttendanceToUserResponse,
  TransferListAttendanceToQueueRequest,
  TransferListAttendanceToQueueResponse,
  TransferListAttendanceToUserRequest,
  TransferListAttendanceToUserResponse,
  TransferVoiceRequest,
  TransferVoiceResponse,
  VerifyFinishAutoReponse,
  VerifyFinishAutoRequest,
} from "models";
import service, { fetchSync } from "services";

// Attendance
export const addAttendance = async (
  params: AddAttendanceRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<AddAttendanceResponse>("Mailing/CreateOmniByIdMailing", {
    ...config,
    params,
  });

export const addAttendanceByDS = async (
  params: AddAttendanceByDSRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<AddAttendanceByDSResponse>(
    "Mailing/CreateOmniByIdMailingDialerAPI",
    { ...config, params }
  );

export const addAttendanceByOmni = async (
  params: AddAttendanceByOmniRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<AddAttendanceByOmniResponse>("Mailing/CreateOmniByIdOmni", {
    ...config,
    params,
  });

export const allowAttendance = async (config?: AxiosRequestConfig) =>
  await service.get<AllowAttendanceResponse>("Omni/AllowQueryMailing", config);

export const cancelTransferAssited = async (
  params: CancelTransferAssitedRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<CancelTransferAssitedResponse>(
    "Omni/CancelTransferCallAssisted",
    { ...config, params }
  );

export const confirmConferenceAttendance = async (
  params: ConfirmConferenceAttendanceRequest,
  config?: AxiosRequestConfig
) =>
  await service.post<ConfirmConferenceAttendanceResponse>(
    "Omni/ConfirmCallConference",
    undefined,
    { ...config, params }
  );

export const confirmTransferAssited = async (
  params: ConfirmTransferAssitedRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<ConfirmTransferAssitedResponse>(
    "Omni/ConfirmTransferCallAssisted",
    { ...config, params }
  );

export const createContact = async (
  params: CreateContactRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<CreateContactResponse>("Mailing/InsertMailing", {
    ...config,
    params,
  });

export const dialAttendance = async (
  params: DialAttendanceRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<DialAttendanceResponse>("Omni/MakeCall", {
    ...config,
    params,
  });

export const dialAttendanceFromOtherMedia = async (
  params: DialAttendanceFromOtherMediaRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<DialAttendanceFromOtherMediaResponse>(
    "Omni/MakeCallFromOtherMedia",
    { ...config, params }
  );

export const dialConferenceAttendance = async (
  params: DialConferenceAttendanceRequest,
  config?: AxiosRequestConfig
) =>
  await service.post<DialConferenceAttendanceResponse>(
    "Omni/MakeCallConference",
    undefined,
    { ...config, params }
  );

export const disableAutoFinish = async (
  params: DisableAutoFinishRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<DisableAutoFinishReponse>("Omni/DisableAutoFinish", {
    ...config,
    params,
  });

export const finishAttendance = async (
  data: FormData,
  params: FinishAttendanceRequest,
  config?: AxiosRequestConfig
) =>
  await service.post<FinishAttendanceResponse>(
    ServicePathEnum.finishAttendance,
    data,
    { ...config, ...formDataConfig, params }
  );

export const finishAttendanceSync = (
  data: FormData,
  params: FinishAttendanceRequest
) =>
  fetchSync<FinishAttendanceResponse>(
    "POST",
    ServicePathEnum.finishAttendance,
    params,
    data
  );

export const finishConferenceAttendance = async (
  params: FinishConferenceAttendanceRequest,
  config?: AxiosRequestConfig
) =>
  await service.post<FinishConferenceAttendanceResponse>(
    "Omni/FinishCallConference",
    undefined,
    { ...config, params }
  );

export const getAttendances = async (
  params: GetAttendancesRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetAttendancesReponse>(ServicePathEnum.getAttendances, {
    ...config,
    params,
  });

export const getAttendancesSync = (params: GetAttendancesRequest) =>
  fetchSync<GetAttendancesReponse>(
    "GET",
    ServicePathEnum.getAttendances,
    params
  );

export const getMeQueues = async (
  params: GetMeQueuesRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetMeQueuesResponse>("User/GetUserOmniRealtime", {
    ...config,
    params,
  });

export const hangupAttendance = async (
  params: HangupAttendanceRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<HangupAttendanceResponse>("Omni/Hangup", {
    ...config,
    params,
  });

export const redialAttendance = async (
  params: RedialAttendanceRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<RedialAttendanceResponse>("Omni/MakeCallRedial", {
    ...config,
    params,
  });

export const scheduleAttendance = async (
  data: FormData,
  params: ScheduleAttendanceRequest,
  config?: AxiosRequestConfig
) =>
  await service.post<ScheduleAttendanceResponse>("Omni/Schedule", data, {
    ...config,
    ...formDataConfig,
    params,
  });

export const scheduleAttendanceSync = (
  data: FormData,
  params: ScheduleAttendanceRequest
) =>
  fetchSync<ScheduleAttendanceResponse>("POST", "Omni/Schedule", params, data);

export const searchAttendance = async (
  params: SearchAttendanceRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<SearchAttendanceResponse>("Mailing/QueryOmni", {
    ...config,
    params,
  });

export const searchContact = async (
  params: SearchContactRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<SearchContactResponse>("Mailing/QueryMailing", {
    ...config,
    params,
  });

export const searchContactInDS = async (
  params: SearchContactInDSRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<SearchContactInDSResponse>(
    "Mailing/QueryMailingDialerAPI",
    { ...config, params }
  );

export const sendHSM = async (
  params: SendHSMRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<SendHSMResponse>("Omni/SendOmniMessageHSM", {
    ...config,
    params,
  });

export const sendHSMFromOtherMedia = async (
  params: SendHSMFromOtherMediaRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<SendHSMFromOtherMediaResponse>(
    "Omni/SendOmniMessageHSMFromOtherMedia",
    { ...config, params }
  );

export const startNewRecord = async (
  params: StartNewRecordRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<StartNewRecordResponse>("Omni/StartNewRecord", {
    ...config,
    params,
  });

export const startNPSSearch = async (
  params: StartNPSSearchRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<StartNPSSearchReponse>("Omni/EndSessionChatExternal", {
    ...config,
    params,
  });

export const transferAssistedAttendance = async (
  data: FormData,
  params: TransferAssistedAttendanceRequest,
  config?: AxiosRequestConfig
) =>
  await service.post<TransferAssistedAttendanceResponse>(
    "Omni/TransferCallAssisted",
    data,
    { ...config, ...formDataConfig, params }
  );

export const transferAttendanceToQueue = async (
  params: TransferAttendanceToQueueRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<TransferAttendanceToQueueResponse>(
    "Omni/TransferOmniToAttendGroup",
    { ...config, params }
  );

export const transferAttendanceToQueueSync = (
  params: TransferAttendanceToQueueRequest
) =>
  fetchSync<TransferAttendanceToQueueResponse>(
    "GET",
    "Omni/TransferOmniToAttendGroup",
    params
  );

export const transferAttendanceToUser = async (
  params: TransferAttendanceToUserRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<TransferAttendanceToUserResponse>("Omni/TransferOmni", {
    ...config,
    params,
  });

export const transferAttendanceToUserSync = (
  params: TransferAttendanceToUserRequest
) =>
  fetchSync<TransferAttendanceToUserResponse>(
    "GET",
    "Omni/TransferOmni",
    params
  );

export const transferListAttendanceToQueue = async (
  params: TransferListAttendanceToQueueRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<TransferListAttendanceToQueueResponse>(
    "Omni/TransferOmniListToAttendGroup",
    { ...config, params, paramsSerializer: { indexes: null } }
  );

export const transferListAttendanceToUser = async (
  params: TransferListAttendanceToUserRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<TransferListAttendanceToUserResponse>(
    "Omni/TransferOmniList",
    { ...config, params, paramsSerializer: { indexes: null } }
  );

export const transferVoice = async (
  data: FormData,
  params: TransferVoiceRequest,
  config?: AxiosRequestConfig
) =>
  await service.post<TransferVoiceResponse>("Omni/TransferCallBlind", data, {
    ...config,
    ...formDataConfig,
    params,
  });

// Verify finish auto
export const verifyFinishAuto = async (
  params: VerifyFinishAutoRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<VerifyFinishAutoReponse>(ServicePathEnum.verifyFinishAuto, {
    ...config,
    params,
  });

export const verifyFinishAutoSync = (params: VerifyFinishAutoRequest) =>
  fetchSync<VerifyFinishAutoReponse>(
    "GET",
    ServicePathEnum.verifyFinishAuto,
    params
  );

// Agent API
export const getAgentAPIs = async (
  params: GetAgentAPIsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetAgentAPIsReponse>(ServicePathEnum.getAgentAPI, {
    ...config,
    params,
  });

export const getAgentAPIsSync = (params: GetAgentAPIsRequest) =>
  fetchSync<GetAgentAPIsReponse>("GET", ServicePathEnum.getAgentAPI, params);

// Message
export const getMessagesId = async (
  params: GetMessagesIdRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetMessagesIdResponse>("Omni/GetOmniMessageListOnLine", {
    ...config,
    params,
  });

export const getMessagesIdSync = (params: GetMessagesIdRequest) =>
  fetchSync<GetMessagesIdResponse>(
    "GET",
    "Omni/GetOmniMessageListOnLine",
    params
  );

export const getMessages = async (
  params: GetMessagesRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetMessagesResponse>("Omni/GetOmniMessageList", {
    ...config,
    params,
    paramsSerializer: { indexes: null },
  });

export const sendFile = async (
  data: FormData,
  params: SendFileRequest,
  config?: AxiosRequestConfig
) =>
  await service.post<SendFileResponse>("Omni/SendOmniMessageFile", data, {
    ...config,
    ...formDataConfig,
    params,
  });

export const sendMessage = async (
  params: SendMessageRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<SendMessageResponse>("Omni/SendOmniMessageText", {
    ...config,
    params,
  });

// Form
export const getFormFields = async (
  params: GetFormFieldsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetFormFieldsResponse>("Omni/GetBindListCustomer", {
    ...config,
    params,
  });

// Historic
export const getHistories = async (
  params: GetHistoriesRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetHistoriesResponse>("Omni/GetOmniTimeLine", {
    ...config,
    params,
  });

// Integration
export const getIntegrations = async (
  params: GetIntegrationsRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetIntegrationsResponse>("Omni/GetOmniIntegrationData", {
    ...config,
    params,
  });

// Order
export const getFormOrder = async (
  params: GetFormOrderRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetFormOrderResponse>("Order/GetBindListOrder", {
    ...config,
    params,
  });

export const getOrderList = async (
  params: GetOrderListRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetOrderListResponse>("Order/GetOrderList", {
    ...config,
    params,
  });

export const manageOrderItem = async (
  data: FormData,
  params: ManageOrderItemRequest,
  config?: AxiosRequestConfig
) =>
  await service.post<ManageOrderItemResponse>("Order/ManagerOrder", data, {
    ...config,
    ...formDataConfig,
    params,
  });

// Quiz
export const answerQuestion = async (
  params: AnswerQuestionRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<AnswerQuestionResponse>("Questionnaire/AnswerQuestion", {
    ...config,
    params,
  });

export const getQuestion = async (
  params: GetQuestionRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetQuestionResponse>("Questionnaire/GetCurrentQuestion", {
    ...config,
    params,
  });

export const getQuiz = async (
  params: GetQuizRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetQuizResponse>("Questionnaire/GetOmniQuestionnaireData", {
    ...config,
    params,
  });

export const previousQuestion = async (
  params: PreviousQuestionRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<PreviousQuestionResponse>("Questionnaire/GetLastQuestion", {
    ...config,
    params,
  });

// Ticket
export const getFormTicket = async (
  params: GetFormTicketRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetFormTicketResponse>("Ticket/GetBindListTicket", {
    ...config,
    params,
  });

export const getTicketList = async (
  params: GetTicketListRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetTicketListResponse>("Ticket/GetTicketList", {
    ...config,
    params,
  });

export const manageTicketItem = async (
  data: FormData,
  params: ManageTicketItemRequest,
  config?: AxiosRequestConfig
) =>
  await service.post<ManageTicketItemResponse>("Ticket/ManagerTicket", data, {
    ...config,
    ...formDataConfig,
    params,
  });

export const sendFilesByTicket = async (
  data: FormData,
  params: SendFilesByTicketRequest,
  config?: AxiosRequestConfig
) =>
  await service.post<SendFilesByTicketResponse>(
    "Ticket/AddFileToTicket",
    data,
    { ...config, ...formDataConfig, params }
  );

// Video Call
export const createRoom = async (config?: AxiosRequestConfig) =>
  await service.get<CreateRoomResponse>("Video/CreateRoom", config);

export const getTokenByRoom = async (
  params: GetTokenByRoomRequest,
  config?: AxiosRequestConfig
) =>
  await service.get<GetTokenByRoomResponse>("Video/GetTokenGuest", {
    ...config,
    params,
  });
